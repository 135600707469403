<!-- ----------------------------------------------------------------------- -->
<!--
name    : APP TENANT EDIT

type    : view

uses    : header-view

route   : /tenants/edit/:uuid
 -->
<!-- ----------------------------------------------------------------------- -->
<template>
  <div class="app-tenant-edit" v-if="tenant">
    <header-view
      title="Edit Tenant"
      :previous_page="'/tenants/view/' + tenant.uuid"
      :btnOptions="[
        {
          name: 'Save',
          action: this.handleSubmit,
        },
      ]" />
    <v-form ref="form">
      <v-container class="mx-2">
        <v-layout align-start justify-start row>
          <v-flex xs12>
            <v-text-field
              id="uuid"
              v-model="tenant.uuid"
              label="Tenant ID"
              disabled></v-text-field>
            <v-text-field
              id="tenant_name"
              v-model="tenant.tenant_name"
              label="Tenant Name"
              :rules="validate_tenant_name"></v-text-field>
            <v-text-field
              id="tenant_url"
              v-model="tenant.tenant_url"
              label="Tenant URL Extension"
              :rules="validate_tenant_url"></v-text-field>
          </v-flex>
          <v-flex xs12>
            <v-select
              id="account_status"
              v-model="tenant.account_status"
              :items="possible_account_status"
              label="Account Status"
              :rules="validate_account_status"></v-select>
            <v-select
              id="subscription_package"
              :items="subscription_packages"
              v-model="tenant.subscription_type"
              label="Subscription"
              :rules="validate_subscription_type"></v-select>

            <v-menu
              ref="menu"
              lazy
              :close-on-content-click="false"
              v-model="menu"
              transition="scale-transition"
              offset-y
              full-width
              :nudge-right="40"
              max-width="290px"
              min-width="290px"
              :return-value.sync="tenant.subscription_start_date">
              <template v-slot:activator="{on}">
                <v-text-field
                  label="Select Start Date"
                  v-model="tenant.subscription_start_date"
                  readonly
                  v-on="on"
                  id="subscription_start_date"></v-text-field>
              </template>
              <v-date-picker
                v-model="tenant.subscription_start_date"
                no-title
                scrollable
                @click="
                  $refs.menu.save(tenant.subscription_start_date)
                "></v-date-picker>
            </v-menu>

            <v-text-field
              v-model="tenant.subscription_end_date"
              label="Subscription Expiration Date"
              :rules="validate_subscription_end_date"
              disabled></v-text-field>
          </v-flex>
        </v-layout>
        <v-layout align-start justify-start row>
          <v-flex xs12>
            <v-text-field
              v-model="tenant.contact_phone"
              label="Contact Phone Number"
              :rules="validate_contact_phone"></v-text-field>
          </v-flex>
          <v-flex xs12>
            <v-text-field
              v-model="tenant.contact_email"
              label="Contact Email Address"
              :rules="validate_contact_email"></v-text-field>
          </v-flex>
        </v-layout>
        <v-layout column justify-start row>
          <v-flex xs12>
            <v-text-field
              id="address"
              v-model="tenant.address"
              label="Address"
              :rules="validate_address"></v-text-field>
          </v-flex>
        </v-layout>
        <v-layout row>
          <v-flex xs12>
            <v-text-field
              id="city"
              v-model="tenant.city"
              label="City"
              :rules="validate_city"></v-text-field>
          </v-flex>
          <v-flex xs12>
            <v-combobox
              id="state"
              v-model="tenant.state"
              label="State"
              :rules="validate_state"
              :items="us_states"></v-combobox>
          </v-flex>
          <v-flex xs12>
            <v-text-field
              v-model="tenant.zip_code"
              label="Postal Code"
              :rules="validate_zip_code"></v-text-field>
          </v-flex>
          <v-flex xs12>
            <v-select
              v-model="tenant.country"
              :items="countryItems"
              label="Country"
              hint=""
              :rules="validate_country"></v-select>
          </v-flex>
        </v-layout>
        <div class="headline" v-if="errorMessage !== ''">
          {{ errorMessage }}
          <v-btn @click="errorMessage = ''">OK</v-btn>
        </div>
      </v-container>
    </v-form>
  </div>
</template>

<script>
  // components
  import HeaderView from '@/components/header-view';

  // mixins
  import Forms from '@/mixins/forms.js';
  import Localization from '@/mixins/localization';

  // services
  import Tenants from '@/services/Tenants.service.js';

  export default {
    name: 'AppTenantEdit',
    components: {
      'header-view': HeaderView,
    },
    mixins: [Forms, Localization],
    props: {
      uuid: {
        type: String,
        required: true,
      },
    },
    data() {
      return {
        menu: false,
        possible_account_status: ['Active', 'Not Active'],
        errorMessage: '',
        loader: false,
        loading4: false,
        subscription_packages: [
          {
            value: '3user',
            text: 'Base',
          },
          {
            value: '10user',
            text: 'Upgraded',
          },
          {
            value: 'UNLIMITED',
            text: 'Unlimited',
          },
          {
            value: 'FREE',
            text: 'Free',
          },
        ],
        tenant: null,
        submitted: false,
        allowTenantEdit: false,
      };
    },
    watch: {
      loader() {
        const l = this.loader;
        this[l] = !this[l];

        setTimeout(() => (this[l] = false), 3000);

        this.loader = null;
      },
    },
    created() {
      this.getAllowed();
    },
    methods: {
      async getAllowed() {
        this.allowTenantEdit = this.$auth.isAllowed('tenants', 'edit');

        // make sure we are allowed
        if (this.allowTenantEdit) {
          // Get the access token from the auth wrapper
          const accessToken = await this.$auth.getTokenSilently();

          // get tenants
          this.tenant = await Tenants.getTenant(this.uuid, accessToken);
        }
      },
      async handleSubmit() {
        if (!this.$refs.form.validate()) {
          console.log('failed validation');
          this.errorMessage = 'check form for validation errors';
          return false;
        }
        // console.log( "Submitting" )
        // Get the access token from the auth wrapper
        const accessToken = await this.$auth.getTokenSilently();

        // set data we can edit (check if we shouldn't be passing the whole tenant object at some point...)
        var data = {
          uuid: this.tenant.uuid,
          tenant_name: this.tenant.tenant_name,
          tenant_url: this.tenant.tenant_url,
          address: this.tenant.address,
          city: this.tenant.city,
          state: this.tenant.state,
          zip_code: this.tenant.zip_code,
          country: this.tenant.country,
          contact_phone: this.tenant.contact_phone,
          contact_email: this.tenant.contact_email,
          account_status: this.tenant.account_status,
          subscription_start_date: this.tenant.subscription_start_date,
          subscription_end_date: this.tenant.subscription_end_date,
          subscription_type: this.tenant.subscription_type,
        };

        // update tenant
        var res = await Tenants.updateTenant(this.uuid, data, accessToken);

        if (res) {
          this.$router.push({
            name: 'TenantView',
            params: {
              uuid: this.uuid,
            },
          });
        } else {
          throw Error('Something went wrong');
        }
      },
    },
  };
</script>

<!-- Add 'scoped' attribute to limit CSS to this component only -->
<style scoped lang="scss">
  h3 {
    margin: 40px 0 0;
  }
  ul {
    list-style-type: none;
    padding: 0;
  }
  li {
    display: inline-block;
    margin: 0 10px;
  }
  a {
    color: #42b983;
  }
</style>
